/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";

$grid-breakpoints: (
  xs: 0,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  lg: 960px,
  xl: 1140px
);

$font-family-base: 'Montserrat', sans-serif;
$font-weight-base: 400;

$primary: #003E80;
$secondary: #6DA9BD;
$light: #8FC4D5;
$warning: #FFDC00;
$nav-link-color: #000000;

$nav-link-padding-y: 0;
$nav-link-padding-x: 0;

$accordion-button-padding-y: 25px;
$accordion-button-padding-x: 0;
$accordion-body-padding-y: 25px; 
$accordion-body-padding-x: 0;
$accordion-button-active-bg: #ffffff;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><line x1='1' y1='10' x2='19' y2='10' stroke='#333333' stroke-width='4' stroke-linecap='square'/><line x1='10' y1='19' x2='10' y2='1' stroke='#333333' stroke-width='4' stroke-linecap='square'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><line x1='1' y1='10' x2='19' y2='10' stroke='#333333' stroke-width='4' stroke-linecap='square'/></svg>");

@import "variables";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
//@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
//@import "dropdown";
//@import "button-group";
@import "nav";
@import "navbar";
/*
@import "card";
*/
@import "accordion";
/*
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
*/
@import "close";
//@import "toasts";
@import "modal";
/*
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";
*/
// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

.bg-light {
    background-color: #EBF1F3 !important;
}

.has-gradient {
    background: rgb(117,189,211);
    background: linear-gradient(135deg, rgba(117,189,211,0.1) 0%, rgba(33,91,123,0.5) 100%);
}

.mt-50,
.mt-sm-50 {
    margin-top: 50px;
}

.ms-50,
.ms-sm-50 {
    margin-left: 50px;
}

.mr-50,
.mr-sm-50 {
    margin-right: 50px;
}

.mb-50,
.mb-sm-50 {
    margin-bottom: 50px;
}

@media (max-width: 575.98px) {
    .mt-sm-50 {
        margin-top: 20px;
    }
    
    .ms-sm-50 {
        margin-left: 20px;
    }
    
    .mr-sm-50 {
        margin-right: 20px;
    }
    
    .mb-sm-50 {
        margin-bottom: 20px;
    }
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.font-900 {
    font-weight: 900;
}

.btn-primary {
    padding: 7px 14px;
    border-radius: 20px;
    font-size: 0.875rem;
    line-height: 1.125rem;
    background-color: var(--bs-warning);
    color: #0E4887;
    text-transform: uppercase;
    border-color: var(--bs-warning);
    font-weight: 700;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
    border-radius: 30px;
    padding: 14px 32px;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 4px 40px rgba(30,30,30,0.4);
}

@media (max-width: 575.98px) {
    .btn-secondary,
    .btn-secondary:focus,
    .btn-secondary:hover {
        padding: 14px 20px;
        font-size: 15px;
    }
}